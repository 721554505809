import { useState, useEffect } from 'react';
import './social.css';

import { Header } from '../../../components/Header';
import { Input } from '../../../components/Input';
import { MdAddLink } from 'react-icons/md';

import { db } from '../../../services/firebaseConnection';
import { setDoc, doc, getDoc } from 'firebase/firestore';

import { toast } from 'react-toastify';

export default function Networks() {
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [youtube, setYoutube] = useState('');

  useEffect(() => {
    function loadLinks() {
      const docRef = doc(db, 'social', 'link');
      getDoc(docRef).then((snapshot) => {
        if (snapshot.data() !== undefined) {
          setFacebook(snapshot.data().facebook);
          setTwitter(snapshot.data().twitter);
          setInstagram(snapshot.data().instagram);
          setYoutube(snapshot.data().youtube);
        }
      });
    }

    loadLinks();
  }, []);

  function handleSave(e) {
    e.preventDefault();

    setDoc(doc(db, 'social', 'link'), {
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
    })
      .then(() => {
        console.log('URLS salva com sucesso');
        toast.success('Salvo com sucesso');
      })
      .catch(() => {
        console.log('Erro ao salvar');
        toast.error('Erro ao salvar');
      });
  }

  return (
    <div className="admin-container">
      <Header />

      <h1 className="title-social">Suas redes sociais</h1>

      <form className="form" onSubmit={handleSave}>
        <label className="label">Link do facebook</label>
        <Input
          placeholder="Digite a url do facebook"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
        />
        <label className="label">Link do twitter</label>
        <Input
          placeholder="Digite a url do twitter"
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
        />
        <label className="label">Link do instagram</label>
        <Input
          placeholder="Digite a url do instagram"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
        />
        <label className="label">Link do youtube</label>
        <Input
          placeholder="Digite a url do youtube"
          value={youtube}
          onChange={(e) => setYoutube(e.target.value)}
        />

        <button type="submit" className="btn-register">
          Salvar links <MdAddLink size={24} color="#fff" />
        </button>
      </form>
    </div>
  );
}
