import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDnqQoDXmrCOk9FR9LfcvR3sBvg0kXoCt8',
  authDomain: 'devlinks-8bfe3.firebaseapp.com',
  projectId: 'devlinks-8bfe3',
  storageBucket: 'devlinks-8bfe3.appspot.com',
  messagingSenderId: '828567185644',
  appId: '1:828567185644:web:fcbced46ecdad5939eddc9',
  measurementId: 'G-PRS6SXV8M7',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
